import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AUTH_TYPE, REDIRECT_URL, USER_TYPE } from '../const/const';
import { Guard } from '../interfaces/guards.interface';
import { getDataFromSession } from '../services/helpers.service';

function RouteGuard({ children, isAuth, type }: Guard) {
  const user = getDataFromSession('data');

  if (
    !isAuth &&
    (type === AUTH_TYPE.ADMIN ||
      type === AUTH_TYPE.DRIVER ||
      type === AUTH_TYPE.RIDER ||
      type === AUTH_TYPE.REFERRAL)
  ) {
    return <Navigate to={REDIRECT_URL.Public} replace />;
  }

  if (
    isAuth &&
    type === AUTH_TYPE.PUBLIC &&
    user != null &&
    user.userType == USER_TYPE.Rider
  ) {
    return <Navigate to={REDIRECT_URL.Rider} replace />;
  }

  if (
    isAuth &&
    type === AUTH_TYPE.PUBLIC &&
    user != null &&
    user.referralCode != null
  ) {
    return <Navigate to={REDIRECT_URL.Referral} replace />;
  }

  if (
    isAuth &&
    type === AUTH_TYPE.PUBLIC &&
    user != null &&
    user.userType == USER_TYPE.Driver
  ) {
    return <Navigate to={REDIRECT_URL.Driver} replace />;
  }

  return children || <Outlet />;
}

RouteGuard.propTypes = {
  children: PropTypes.node,
  isAuth: PropTypes.bool,
  type: PropTypes.string
};

RouteGuard.defaultProps = {
  isAuth: false,
  type: AUTH_TYPE.PUBLIC
};

export default RouteGuard;
