import React, { lazy, Suspense, useEffect, useState } from 'react';
import { CookiesProvider } from 'react-cookie';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import RouteGuard from './core/guards/route.guards';
import { AUTH_TYPE } from './core/const/const';
import Loader from './core/components/basic/loader.component';
import { ReactNotifications } from 'react-notifications-component';
import {
  getDataFromSession,
  GlobalDebug
} from './core/services/helpers.service';

const ReferralAuth = lazy(() => import('./pages/referral/ReferralAuth'));
const RiderAuth = lazy(() => import('./pages/rider/RiderAuth'));
const DriverAuth = lazy(() => import('./pages/driver/DriverAuth'));
const Landing = lazy(() => import('./pages/public/landing.page'));
const DriverLogin = lazy(() => import('./pages/public/driverLogin.page'));
const NotFound = lazy(() => import('./pages/public/notfound.public.page'));
const ForgetPassword = lazy(() => import('./pages/public/forget.page'));
const ResetPassword = lazy(() => import('./pages/public/reset.page'));
const TripPayment = lazy(() => import('./pages/public/trippayment.page'));
const ReferralLogin = lazy(() => import('./pages/public/referralLogin.page'));

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    }
  });

  const [isAuth, setIsAuth] = useState(
    getDataFromSession('data') != null ? true : false
  );

  const Logout = async () => {
    setIsAuth(false);
    localStorage.clear();
    sessionStorage.clear();
  };

  useEffect(() => {
    const user = getDataFromSession('data');
    if (user != null) setIsAuth(true);
  }, []);

  useEffect(() => {
    (process.env.NODE_ENV === 'production' ||
      process.env.REACT_APP_ENV === 'STAGING') &&
      GlobalDebug(false);
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <CookiesProvider>
        <Router>
          <div className="absolute top-0">
            <ReactNotifications />
          </div>
          <Suspense fallback={<Loader />}>
            <Routes>
              <Route
                element={<RouteGuard isAuth={isAuth} type={AUTH_TYPE.PUBLIC} />}
              >
                <Route
                  path="/"
                  element={<Landing setAuth={() => setIsAuth(true)} />}
                />
              </Route>

              <Route
                element={<RouteGuard isAuth={isAuth} type={AUTH_TYPE.PUBLIC} />}
              >
                <Route
                  path="/logistics"
                  element={<DriverLogin setAuth={() => setIsAuth(true)} />}
                />
              </Route>

              <Route
                element={<RouteGuard isAuth={isAuth} type={AUTH_TYPE.PUBLIC} />}
              >
                <Route
                  path="/agencies"
                  element={<ReferralLogin setAuth={() => setIsAuth(true)} />}
                />
              </Route>

              <Route
                element={<RouteGuard isAuth={isAuth} type={AUTH_TYPE.PUBLIC} />}
              >
                <Route path="/forget-password" element={<ForgetPassword />} />
              </Route>

              <Route
                element={<RouteGuard isAuth={isAuth} type={AUTH_TYPE.PUBLIC} />}
              >
                <Route
                  path="/reset-password/:username"
                  element={<ResetPassword />}
                />
              </Route>

              <Route
                element={<RouteGuard isAuth={isAuth} type={AUTH_TYPE.PUBLIC} />}
              >
                <Route path="/trip/helpafriend" element={<TripPayment />} />
              </Route>

              <Route
                element={<RouteGuard isAuth={isAuth} type={AUTH_TYPE.RIDER} />}
              >
                <Route
                  path="/admin-rider/*"
                  element={<RiderAuth logout={() => Logout()} />}
                />
              </Route>
              <Route
                element={<RouteGuard isAuth={isAuth} type={AUTH_TYPE.DRIVER} />}
              >
                <Route
                  path="/admin-driver/*"
                  element={<DriverAuth logout={() => Logout()} />}
                />
              </Route>
              <Route
                element={
                  <RouteGuard isAuth={isAuth} type={AUTH_TYPE.REFERRAL} />
                }
              >
                <Route
                  path="/admin-referral/*"
                  element={<ReferralAuth logout={() => Logout()} />}
                />
              </Route>
              <Route
                element={<RouteGuard isAuth={isAuth} type={AUTH_TYPE.PUBLIC} />}
              >
                <Route path="*" element={<NotFound />} />
              </Route>
            </Routes>
          </Suspense>
        </Router>
      </CookiesProvider>
    </QueryClientProvider>
  );
}

export default App;
