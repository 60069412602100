export const REDIRECT_URL = {
  Rider: '/admin-rider/',
  Public: '/',
  Driver: '/admin-driver',
  Admin: '/admin',
  Referral: '/admin-referral'
} as const;

export const AUTH_TYPE = {
  RIDER: 'Rider ',
  PUBLIC: 'Public',
  DRIVER: 'Driver',
  ADMIN: 'Admin',
  REFERRAL: 'Referral'
} as const;

export const RIDE_STATUS = {
  UNASSIGNED: 'unassigned ',
  ASSIGENED: 'assigned',
  INTRANSIT: 'intransit',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
  CANCELED: 'canceled'
} as const;

export const USER_TYPE = {
  Rider: 1,
  Driver: 4,
  Public: 0
} as const;

export type RedirectUrl = (typeof REDIRECT_URL)[keyof typeof REDIRECT_URL];

export type AuthType = (typeof AUTH_TYPE)[keyof typeof AUTH_TYPE];
